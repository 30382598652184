import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";
import { vars } from "../../../css/vars.css";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlSpace="preserve" id="Layer_1" x={0} y={0} viewBox="0 0 1371.4 345.1" {...props}>
      <style>{".st0{fill:#f5ca74}.st11{fill:#e2b25b}"}</style>

      <path
        fill={vars.colors.contrast}
        d="M767.8 170.6c16.3 3.3 37.7 11.2 37.3 35.1 0 11.4-4.3 20.4-13 26.7-8.4 6.1-19.2 9.2-32 9.2-23 0-38.7-9-46.9-26.7l25.1-14.1c3.3 9.6 10.6 14.5 22 14.5 10.2 0 15.5-3.1 15.5-9.6 0-6.1-9-9.4-20.4-12.4-16.5-4.3-37.3-11.2-37.3-34.5 0-11 4.1-19.6 12.2-25.9 8.2-6.5 18.3-9.6 30.4-9.6 18.1 0 33.8 8.4 42.6 23.8l-24.5 13.3c-3.5-7.7-9.6-11.4-17.9-11.4-7.5 0-13 3.5-13 9.2-.5 6.3 8.5 9.7 19.9 12.4zM665.3 205.9l-5.5 8 15.9 22.6h33.6L676.7 190l-11.4 15.9zM676.7 174.1l33-46.9h-33.6l-16.3 23.2 5.5 7.9 11.4 15.8zM654.3 158.2l-22-31h-33.6l38.7 55-38.3 54.4h33.6l21.6-30.6 16.7-23.8-16.7-24zM411.1 168.6v67.1h-28.3v-65.2c0-13.2-6.7-20.6-18.3-20.6-12.8 0-20.2 8.6-20.2 24.5v61.2h-28.1v-65.2c0-13.2-6.7-20.6-18.3-20.6-12.4 0-20.4 8.6-20.4 24.5v61.2h-28.3V126.8h28.1v11.6c6.5-9.8 16.5-14.5 30.2-14.5 13.3 0 23.4 5.3 29.6 15.7 7.3-10.4 18.1-15.7 32.4-15.7 25.3-.2 41.6 17.5 41.6 44.7z"
      />
      <path
        fill={vars.colors.contrast}
        d="M449 117.2c9.6 0 17.5-7.7 17.5-16.7 0-9.2-7.9-17.1-17.5-17.1-9.4 0-17.3 7.9-17.3 17.1 0 9.1 7.9 16.7 17.3 16.7z"
        className="st0"
      />
      <path
        fill={vars.colors.contrast}
        d="M463.1 126.8H435v108.9h28.1V126.8zM512.6 192.8c3.7 13.7 13.9 20.4 30.8 20.4 10.6 0 19-3.7 24.3-11l22.6 13.2c-10.6 15.5-26.5 23.4-47.5 23.4-18.1 0-32.4-5.5-43.4-16.3s-16.3-24.5-16.3-41.2c0-16.3 5.5-30 16.1-41 10.6-11.2 24.5-16.5 41.4-16.5 15.9 0 29.2 5.5 39.5 16.5 10.4 11.2 15.7 24.5 15.7 41 0 3.7-.4 7.5-1.2 11.6h-82zm55.2-21.8c-3.3-14.7-14.1-22-27.1-22-15.1 0-25.3 8-28.5 22h55.6z"
      />
      <path
        d="M892.7 170.6c16.3 3.3 37.5 11.2 37.3 35 0 11.4-4.2 20.3-12.9 26.5-8.5 6-19.2 9.1-31.9 9.1-23 0-38.6-8.9-46.8-26.5l25-14.1c3.3 9.6 10.5 14.5 21.9 14.5 10.3 0 15.4-3.1 15.4-9.6 0-6-8.9-9.4-20.3-12.3-16.5-4.2-37.3-11.2-37.3-34.3 0-10.9 4-19.6 12-25.9 8.3-6.5 18.3-9.6 30.3-9.6 18.1 0 33.7 8.5 42.4 23.6l-24.5 13.4c-3.6-7.6-9.6-11.4-17.8-11.4-7.4 0-12.9 3.6-12.9 9.1-.2 6.3 8.8 9.6 20.1 12.5zM1070.7 126.7h30.6l-35.5 111.5h-27.4l-20.5-67.6-20.5 67.6H970l-35.5-111.5H965l19 68.5 20.3-68.5h27.2l20.3 68.7 18.9-68.7zM1197.2 126.7h28.8v111.5h-28.8V225c-8.7 10.9-20.7 16.3-36.4 16.3-14.9 0-27.7-5.6-38.4-17-10.5-11.4-15.8-25.4-15.8-41.9s5.4-30.3 15.8-41.7c10.7-11.4 23.4-17.2 38.4-17.2 15.6 0 27.7 5.4 36.4 16.3v-13.1zm-31 87.2c8.9 0 16.3-2.9 22.1-8.7 6-6 8.9-13.6 8.9-22.8 0-9.1-2.9-16.7-8.9-22.5-5.8-6-13.2-8.9-22.1-8.9s-16.3 2.9-22.1 8.9c-5.8 5.8-8.7 13.4-8.7 22.5s2.9 16.7 8.7 22.8c5.8 5.8 13.2 8.7 22.1 8.7zM1355.4 140.7c10.7 11.4 16.1 25.2 16.1 41.7s-5.4 30.6-16.1 41.9c-10.5 11.4-23.4 17-38.4 17-15.6 0-27.7-5.4-36.1-16.3v57.8h-28.8V126.7h28.8v13.2c8.5-10.9 20.5-16.3 36.1-16.3 14.9 0 27.9 5.8 38.4 17.1zm-43.7 73.2c8.9 0 16.3-2.9 22.1-8.7 6-6 8.9-13.6 8.9-22.8 0-9.1-2.9-16.7-8.9-22.5-5.8-6-13.2-8.9-22.1-8.9-8.9 0-16.3 2.9-22.1 8.9-5.8 5.8-8.7 13.4-8.7 22.5s2.9 16.7 8.7 22.8c5.8 5.8 13.1 8.7 22.1 8.7z"
        className="st0"
      />
      <path
        d="M15 166.1s-2.7 2.5-1.8 13.4c.9 10.9 6.2 57.2 6.2 57.2s164.8-103.2 182.5-114.6 38.8-40.4 38.8-40.4-1.2-7.3-17.7-17.3-24.1-11.4-31.2-10.8c-7.1.7-20.8 5.7-22.9 6.9-2.1 1.1-41 4.3-41 4.3s-13.7 5.4-22.2 15.5L58.2 107s-10.5 7.1-11.7 19.2c-1.2 12-31.5 39.9-31.5 39.9z"
        style={{
          fill: "#f7edde",
        }}
      />
      <path
        d="M249.8 72.1c-1.7-2.1-3.8-4-6.5-5.7-15.3-9.7-33.4-12.8-51.5-12.8.6.4 1.2.9 1.7 1.4 4.7 4.1 7.9 9.6 9.3 15.2 1.4 5.7 1.3 11.4-.6 16.8-3-4.9-5.3-9.3-8-13.3-5.5-8.4-11.8-13.9-21.2-17.3-2.8-1-5.7-1.3-8.6-.9-7.2 1-14.3 2.5-21.2 4.4-2.6.7-5.1 1.4-7.6 2.2-1.3.4-2.6.8-4 1.3-.6.2-3.1 1.5-3.6 1.3 1.4.6 2.8 1.3 4.2 2.1 3.1 1.9 5.8 4.4 7.9 7.2 2.1 2.8 3.6 6 4.6 9.2 2 6.4 2 13 .2 19.1-3.1-5.6-5.6-10.8-8.6-15.2-3-4.4-6.4-7.8-10.3-10.3-.5-.3-1.1-.6-1.8-.9-7.5-3.5-16.2-2.9-23.5.9-1 .5-2 1.1-3 1.6-8 4.4-15.7 9.4-23 14.8-5.7 4.2-11.1 8.8-16.4 13.6 1.4-.3 2.9-.4 4.3-.4 3.2 0 6.5.6 9.4 1.7 6 2.3 10.8 6.4 14.2 11.2 3.3 4.8 5.4 10.3 5.7 16.1-4.6-3.5-8.5-6.9-12.4-9.7-3.9-2.8-7.7-5.1-11.6-6.6-.5-.2-1.2-.3-2-.5-10.9-1.9-22 2.5-28.8 11.2-8.7 11.1-16.2 23.2-22.1 36 4.5-2.8 10.2-4.3 15.9-3.8 6.2.6 11.9 3.6 15.8 7.7 4 4.1 6.6 9.2 7.2 14.6-4.8-2.8-8.8-5.4-12.8-7.2-4-1.8-7.7-3-11.4-3.4-3.8-.3-7.6.1-11.8 1.1l-7.4 1.8c-3.8 10.1-6.7 20.5-8.4 31.2-3 18.7-1.2 47.7 24.1 45.2 3.3-.3 13.2-2.7 17-4.5 0 0 31.5-13.2 106.1-65.1 76.6-53.3 95.5-71.4 95.5-71.4 9.4-9.1 13.2-18.3 11.8-27.7-.9-4.9-2.8-7.2-6.8-12.2zM37.7 153.3c-1.1.8-2.7.5-3.5-.6-.8-1.1-.5-2.7.6-3.5 1.1-.8 2.7-.5 3.5.6.8 1.1.5 2.7-.6 3.5zm56.5 20.6c-1.1.8-2.7.5-3.5-.6-.8-1.1-.5-2.7.6-3.5s2.7-.5 3.5.6c.8 1.1.5 2.7-.6 3.5zm-18.5-30.5c-1.1.8-2.7.5-3.5-.6-.8-1.1-.5-2.7.6-3.5s2.7-.5 3.5.6c.8 1.2.5 2.8-.6 3.5zm17.5-35.8c-1.1.8-2.7.5-3.5-.6-.8-1.1-.5-2.7.6-3.5s2.7-.5 3.5.6c.8 1.2.5 2.8-.6 3.5zm52.6 34.7c-1.1.8-2.7.5-3.5-.6-.8-1.1-.5-2.7.6-3.5 1.1-.8 2.7-.5 3.5.6.8 1.2.6 2.7-.6 3.5zm-14.6-27.6c-1.1.8-2.7.5-3.5-.6-.8-1.1-.5-2.7.6-3.5 1.1-.8 2.7-.5 3.5.6s.5 2.7-.6 3.5zM166.8 80c-1.1.8-2.7.5-3.5-.6-.8-1.1-.5-2.7.6-3.5 1.1-.8 2.7-.5 3.5.6.8 1.2.5 2.8-.6 3.5zm36 21.8c-1.1.8-2.7.5-3.5-.6-.8-1.1-.5-2.7.6-3.5 1.1-.8 2.7-.5 3.5.6s.6 2.7-.6 3.5z"
        className="st0"
      />
      <path
        d="M53 184c-4.5-2.6-8.2-5-12-6.7-4-1.8-7.7-3-11.4-3.4-3.8-.3-7.6.1-11.8 1.1l-7.4 1.8c-1 2.6-1.9 5.2-2.7 7.8-.1 0 17.9-10.6 45.3-.6zM69.4 126.6c14.4 5.6 22.4 8.9 22.4 8.9-4.6-3.5-8.5-6.9-12.4-9.7-3.9-2.8-7.7-5.1-11.6-6.6-.5-.2-1.2-.3-2-.5-10.1-1.8-20.4 1.9-27.3 9.4.2-.1 16.6-7.1 30.9-1.5zM144.6 102.2c-3.1-5.6-5.6-10.8-8.6-15.2-3-4.4-6.4-7.8-10.3-10.3-.5-.3-1.1-.6-1.8-.9-7.5-3.5-16.2-2.9-23.5.9-1 .5-2 1.1-3 1.6.1.1 23.1-8.5 47.2 23.9zM202.1 86.9c-3-4.9-5.3-9.3-8-13.3-5.5-8.4-11.8-13.9-21.2-17.3-2.8-1-5.7-1.3-8.6-.9-1.1.2-2.1.3-3.2.5 1.3.2 20.7 2.3 41 31z"
        className="st11"
      />
      <path
        d="M43.6 106.8c0-1.4-.5-2.5-1.3-3.4-.7-.9-1.6-1.6-2.6-2.2-1.9-1.2-4.2-2.1-6.7-3.4-1.2-.7-2.5-1.5-3.8-2.6-1.3-1.1-2.5-2.6-3.3-4.2-.8-1.7-1.2-3.5-1.3-5.2-.1-1.7.1-3.4.4-5 .4-1.6.9-3.1 1.5-4.5.7-1.3 1.3-2.6 2.1-3.8 1.5-2.4 3-4.4 4.4-6.4 1.4-2 2.5-4 3.3-6.2.8-2.2 1-4.6.2-7.3 2.3 1.6 3.7 4.7 3.9 7.7.2 3-.5 6.1-1.5 8.7-1 2.7-2.3 5.1-3.3 7.3-.5 1.1-1.1 2.2-1.5 3.3-.4 1-.8 2-1 3-.5 2-.5 3.8 0 5.3.2.8.6 1.4 1.2 2.2.6.8 1.4 1.6 2.3 2.4.9.8 2 1.7 3 2.6 1 .9 2.1 2 3 3.3.9 1.2 1.6 2.7 1.9 4.2.1.8.2 1.6 0 2.3-.2.6-.5 1.3-.9 1.9zM136.7 39.2c0-.6-.2-1.1-.5-1.5-.3-.4-.7-.7-1.1-1-.8-.5-1.9-.9-2.9-1.5-.5-.3-1.1-.6-1.7-1.1-.6-.5-1.1-1.1-1.4-1.9-.4-.7-.5-1.5-.6-2.3 0-.8 0-1.5.2-2.2.2-.7.4-1.3.7-1.9.3-.6.6-1.1.9-1.7.6-1 1.3-1.9 1.9-2.8.6-.9 1.1-1.7 1.4-2.7.3-1 .4-2 .1-3.2 1 .7 1.6 2 1.7 3.4.1 1.3-.2 2.7-.7 3.8-.4 1.2-1 2.2-1.5 3.2-.2.5-.5 1-.6 1.4-.2.4-.3.9-.4 1.3-.2.9-.2 1.7 0 2.3.1.3.3.6.5 1 .3.3.6.7 1 1 .4.4.9.7 1.3 1.1.4.4.9.9 1.3 1.4.4.5.7 1.2.8 1.8.1.3.1.7 0 1 0 .5-.2.8-.4 1.1zM94.7 63.1c0-1.6-.6-2.9-1.4-3.9-.8-1-1.9-1.8-3-2.6-2.2-1.4-4.9-2.4-7.7-3.9-1.4-.8-2.9-1.7-4.4-2.9-1.5-1.2-2.8-3-3.7-4.9-.9-1.9-1.4-4-1.5-6-.1-2 .1-3.9.5-5.7.4-1.8 1-3.5 1.8-5.1.7-1.5 1.5-3 2.4-4.3 1.7-2.7 3.4-5.1 5-7.4 1.6-2.3 2.9-4.5 3.7-7.1.9-2.5 1.1-5.3.2-8.4 2.7 1.8 4.2 5.4 4.4 8.8.3 3.5-.6 7-1.7 10-1.1 3.1-2.6 5.8-3.8 8.4-.6 1.3-1.2 2.5-1.7 3.7-.5 1.2-.9 2.3-1.1 3.5-.5 2.3-.6 4.4 0 6 .3.9.7 1.6 1.4 2.5.7.9 1.6 1.8 2.7 2.7 1.1.9 2.2 1.9 3.4 3 1.2 1.1 2.4 2.3 3.4 3.8 1 1.4 1.8 3 2.1 4.8.2.9.2 1.8 0 2.6-.2 1-.5 1.8-1 2.4z"
        className="st0"
      />
    </Svg>
  );
};

export default Logo;
